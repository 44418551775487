<template>
    <main id="entityAct">
        <div class="entity-act">
            <div class="box p-2">
                <strong>RJSC accords registration and ensures lawful administration of the entities under the provisions of applicable
                    act as
                    under:</strong>
                <ul class="basic-list">
                    <li>Companies and Trade Organizations: Companies Act, 1994 (Amendment of Companies Act 1913)</li>
                    <li>Societies: Societies Registration Act, 1860</li>
                    <li>Partnership Firms: Partnership Act, 1932</li>
                </ul>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    name: 'entityAct',
    components: {
    }
}
</script>
